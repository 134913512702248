import React, {useEffect, useState, useRef, useLayoutEffect} from "react";
import {connect} from "react-redux";
import Scanner from "@components/Scanner/Scanner";
import {FormattedMessage} from "react-intl";
import {Button} from "antd";
import CustomSelectModal from "@components/CustomSelectModal/CustomSelectModal";
import cardsEnum from "@constants/cards";
import {subscribeTickerWorker, unSubscribeTickerWorker, getTimeFromTickerWorker} from "@actions/workerActions";
import {playWarningSound} from "@services/soundServices";



function Timer({scanCard, setErrorMsg, autoFocus, subscribeTickerWorker, unSubscribeTickerWorker, getTimeFromTickerWorker, cards, disabledCustomSelect, winner}) {
    const [showSelectModal, setShowSelectModal] = useState(false)
    const [betDuration, setBetDuration] = useState()
    const [ticker, setTicker] = useState(undefined);

    useEffect(() => {
        function getServerTime(e) {
            const time = JSON.parse(e.data);
            setBetDuration(time.data)
        }
        subscribeTickerWorker(getServerTime);
        return () => {
            unSubscribeTickerWorker(getServerTime)
        }
    }, [])

    useLayoutEffect(() => {
        autoFocus()
    }, [showSelectModal])

    useEffect(() => {
        if (cards.length < 6 && winner) {
            setTicker(0)
        } else {
            setTicker(betDuration === undefined ? undefined : parseInt( betDuration / 1000))
        }

    }, [betDuration])




    const selectCard = (value ,deck) => {
        if (ticker > 3) {
            playWarningSound();
            setErrorMsg(ticker !== undefined ? "bet_duration_is_not_over" : "round_is_not_started");
        }
        let code = ""
        try {
            cardsEnum.forEach(item => {
                let cardName = item.CardName.slice(0, -1)
                let suit = item.CName.split(" ")[2].toLowerCase()
                if (value === cardName + suit && item.Deck == deck) {
                    code = item.Code
                    throw 'Break'
                }
            })
        } catch (e) {
            if (e !== 'Break') throw e
        }

        let existingCardIndex = cards.findIndex(card => card.code == code)

        if (existingCardIndex === -1) {
            setShowSelectModal(false)
            scanCard(code)
            getTimeFromTickerWorker()
        } else {
            setErrorMsg("card_already_opened")
        }
    }
    return(<div className="ticker">
        <div className="tickerArea">{ ticker > 0 && ticker}</div>
        <Button className="miniBtn"
                disabled={ticker > 0 || disabledCustomSelect}
                onClick={() => setShowSelectModal(true)}>
            <FormattedMessage id='select'/>
        </Button>
        <Scanner ticker={ticker}/>
        <CustomSelectModal visible={showSelectModal}
                           onCancel={() => setShowSelectModal(false)}
                           onConfirm={selectCard}
        />
    </div>)
}

function mapStateToProps(state) {
    return {
        cards: state.cards,
        winner: state.winner
    }
}

const mapDispatchToProps = {
    subscribeTickerWorker,
    unSubscribeTickerWorker,
    getTimeFromTickerWorker
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer)