export default {
    settings: {
        lang: {
            id: 1
        },
        gameConfig: {
            autoStart: false,
            autoResult: false,
            timer: 12
        }
    },
    user: {},
    emptyObj: {},
    emptyArr: [],
    playersList: {
        list: [],
        listModalVisible: false,
        isLoading: false
    },
    allowCancel: {
        allowBets: false,
        allowTransaction: false
    },
    cards: [],
    timer:{
        timerStart: undefined,
        timerEnd: undefined
    }
}