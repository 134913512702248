import React from "react";
import classNames from "classnames";
import cardBg from "@assets/img/cardBg.svg";

function CardListItem({cardNumber, cardList, ...restProps}) {
    return(<li className="cards-list-item" {...restProps}>
        <div className={classNames("flip-card", {active: cardList[cardNumber]})}>
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <img src={cardBg} alt={cardBg} />
                </div>
                <div className="flip-card-back">
                    <i className={`cardIcon icon-${cardList[cardNumber]}`}/>
                </div>
            </div>
        </div>
    </li>)
}

export default CardListItem