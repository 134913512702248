export const DefaultLanguage = 'EN';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const cardName = [
     "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"
]

export const CardTypeMap = {
    1: "A",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "J",
    12: "Q",
    13: "K",
    14: "A",
    15: "Joker1",
    16: "Joker2"
}

export const CardsSuit = {
    0: "spades",
    1: "clubs",
    2: "hearts",
    3: "diamonds"
}

// public enum UpdateType
// {
//     Ping,
//     Error,
//     RoundStart,
//     Card,
//     Odd,
//     Timer,
//     Result,
//     Bet,
//     Pair,
//     BetHistory,
//     WinnerList,
//     PlayerBets,
//     Video,
//     UserInfo,
//     PlayerLoad,
//     RoundCanceled,
//     RoundHistory,
//     BalanceUpdate,
//     Connect
// }

export const winnerEnum = {
    0: "undefined",
    1: "dragonWin",
    2: "tigerWin",
    3: "tie",
    4: "suitedTie",
    undefined: 0,
    dragonWin: 1,
    tigerWin: 2,
    tie: 3,
    suitedTie: 4
}


export const decksCount = 8