import React from "react";
import CardListItem from "@components/CardsList/CardListItem";
import classNames from "classnames";

function CardList({cards, className}) {
    return(
        <ul className={classNames("cards-list", className)}>
            {
                [0].map((cardNumber, index) => (<CardListItem cardNumber={cardNumber} key={index} cardList={cards}/>))
            }
        </ul>
    )
}

export default CardList