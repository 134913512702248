import {
    SET_CARD,
    SET_CARD_LIST
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import cards from "@constants/cards";
import {CardsSuit, CardTypeMap} from "@constants/Enums"

export default function cardsReducer(state = initialStore.cards, action) {
    switch (action.type) {
        case SET_CARD_LIST:
            const cards = action.codes.filter(card => card.cardSuit != null);
            if (cards.length) {
                return cards.map(item => {
                    return {
                        value: `${CardTypeMap[item.cardNumber]}${CardsSuit[item.cardSuit]}`,
                        code: item.code
                    };
                })
            } else {
                return cards;
            }
            
        default:
            return state;
    }
}