import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {gameCancel, gameStart, gameResult, gameInfo, gameIsActive} from "@actions/gameControl";
import {FormattedMessage} from "react-intl";
import {Button, Space, Row, Col, Switch} from "antd";
import CardList from "@components/CardsList/CardsList";
import {scanCard} from "@actions/cardAction";
import Timer from "@components/Timer/Timer";
import {setErrorMsg} from "@actions/alertAction"
import classNames from "classnames";
import {winnerEnum} from "@constants/Enums";
import Settings from "@components/Settings";

function GameControl({
                         gameCancel,
                         gameStart,
                         gameResult,
                         gameInfo,
                         loading,
                         cards,
                         scanCard,
                         timer,
                         setErrorMsg,
                         winner,
                         gameIsActive,
                         isActive,
                         roundId
                     }) {
    const [activeArea, setActiveArea] = useState("")
    const [playerACardList, setPlayerACardList] = useState([]);
    const [online, setOnline] = useState(navigator.onLine);
    const [playerBCardList, setPlayerBCardList] = useState([]);
    const timeOut = useRef([])

    useEffect(() => {
        function enable(e) {
            setOnline(true)
        }
        function disable(e) {
            setOnline(false)
        }
        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);

        return () => {
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
        }
    },[])

    useEffect(() => {
        if (timer.timerEnd || cards.length) {
            setActiveArea(cards.length == 2? "" : cards.length % 2 !== 0 ? "Tiger" : "Dragon")
        } else {
            setActiveArea("")
        }
    }, [timer, cards])

    useEffect(() => {
            let cardsA = [];
            let cardsB = [];
            cards.forEach((card, index) => {
                if (index % 2 !== 0) {
                    cardsA.push(card.value)
                } else {
                    cardsB.push(card.value)
                }
            })
            setPlayerACardList(cardsA)
            setPlayerBCardList(cardsB)
    }, [cards])

    useEffect(() => {
        if (online) {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
            gameInfo()
        }
    },[online])

    useEffect(() => {
        if (!loading._game_info && online) {
            timeOut.current.push(setInterval(() => {gameInfo()}, 1000))
        }
        return () => {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
        }
    }, [loading._game_info])


    const autoFocus = () => {
        window.focus();
        if (document.activeElement) {
            document.activeElement.blur();
        }
    }

    return (<div>
        <div className="titleArea">
            <div className="titleArea--title"><FormattedMessage id='game_control'/></div>
            <div className="titleArea--BtnGroup">
                <Settings />
                <div className="game-show-switch">
                    <span><FormattedMessage id="enable_game" /></span>
                     <Switch loading={loading._game_is_active}
                        onClick={() => gameIsActive(!isActive)}
                        disabled={activeArea}
                        checked={isActive}/>
                </div>
               
            </div>
        </div>
        <div className="game-control">
            {isActive ?
                <>
                    <Row className="game-control-row" justify="space-between" align="center">
                        <Col span={10} className={classNames("game-control-col", {active: activeArea === "Dragon"})}>
                            {
                                <h2 className={classNames("winner", {
                                    draw: winner === winnerEnum.tie || winner === winnerEnum.suitedTie,
                                    show: winner === winnerEnum.dragonWin
                                })}>
                                    <FormattedMessage id={winner === winnerEnum.tie ? "tie" : winner === winnerEnum.suitedTie? "suited_tie" : "winner"}/>
                                </h2>
                            }
                            <div className="title">Dragon</div>
                            <CardList cards={playerBCardList}/>
                        </Col>
                        <div className="custom-control">
                            <Timer timer={timer}
                                   scanCard={scanCard}
                                   setErrorMsg={setErrorMsg}
                                   autoFocus={autoFocus}
                                   disabledCustomSelect={!activeArea}
                            />
                        </div>
                        <Col span={10}
                             className={classNames("game-control-col", {active: activeArea === "Tiger"})}>
                            {
                                <h2 className={classNames("winner", {
                                    draw: winner === winnerEnum.tie || winner === winnerEnum.suitedTie,
                                    show: winner === winnerEnum.tigerWin
                                })}>
                                    <FormattedMessage id={winner === winnerEnum.tie ? "tie" : winner === winnerEnum.suitedTie? "suited_tie" : "winner"}/>
                                </h2>
                            }

                            <div className="title">Tiger</div>
                            <CardList cards={playerACardList} className="reverse"/>
                        </Col>
                    </Row>
                    <Row  justify="space-between" align="center">
                        <Col span={8}  className="control-buttons"> </Col>
                        <Col span={8}  className="control-buttons">
                            <Space>
                                
                                
                                {
                                    <>
                                        {
                                            !roundId ?
                                            <Button loading={loading._game_start}
                                                    disabled={loading._game_result || loading._game_start || loading._game_cancel || activeArea || cards.length}
                                                    className="miniBtn"
                                                    size="large"
                                                    onClick={gameStart}>
                                                <FormattedMessage id='start'/>
                                            </Button>
                                                : ""
                                        }
                                        {
                                            winner ?
                                            <Button loading={loading._game_result}
                                                    disabled={loading._game_result || loading._game_start || loading._game_cancel || cards.length !== 2}
                                                    className="miniBtn"
                                                    size="large"
                                                    onClick={gameResult}>
                                                <FormattedMessage id='result'/>
                                            </Button>
                                                : ""
                                        }

                                    </>
                                }
                            </Space>
                        </Col>
                        <Col span={8}  className="control-buttons">
                            <Space>
                                {
                                    !roundId  ? "" : <Button loading={loading._game_cancel}
                                        disabled={loading._game_result || loading._game_start || loading._game_cancel}
                                        className="miniBtn"
                                        size="large"
                                        onClick={gameCancel}>
                                    <FormattedMessage id='cancel'/>
                                </Button>
                                }
                            </Space>
                        </Col>
                    </Row>
                </> :
                <div className="message">
                    <FormattedMessage id="table_is_not_active"/>
                </div>}


        </div>


    </div>)
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        cards: state.cards,
        timer: state.timer,
        winner: state.winner,
        isActive: state.gameIsActive,
        roundId: state.roundId
    }
}

const mapDispatchToProps = {
    gameCancel,
    gameStart,
    gameResult,
    scanCard,
    gameInfo,
    setErrorMsg,
    gameIsActive
}

export default connect(mapStateToProps, mapDispatchToProps)(GameControl)